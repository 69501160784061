import { ActionTree } from "vuex";
import { TriageState } from "./state";
import { StateInterface } from "../index";
import {
  triagePatientApi,
  triageGeneralApi,
  initialAttentionsApi,
} from "@/apis";
import { IError } from "@/interfaces/customs/interface";
import { ITriage } from "@/interfaces/global/interfaces";

const actions: ActionTree<TriageState, StateInterface> = {
  async getTriages({ commit }, { filters, page }): Promise<object> {
    try {
      const { data } = await triagePatientApi(filters, page).get(``);
      const { success, message } = data;
      data.data.data.forEach((element: ITriage) => {
        element["patient_name"] = `${element?.patient?.name} ${element?.patient?.last_name}`;
        element["document"] = element?.patient?.document;
        element["gender"] = element?.patient?.gender;
        element["eps_name"] = element?.patient?.eps?.name;
        element["age"] = element?.patient?.age;
        element["classification"] = element?.last_patient_triage?.triage.name;
        element["classification_time"] = element?.last_patient_triage?.created_at_formatted;
        element["type"] = element?.last_patient_triage?.type;
        element["admission"] = `${element?.registered_date_formatted} ${element?.registered_time_formatted}`
      });
      commit("setTriages", data.data);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async createTriage({ commit }, { dataRequest }) {
    try {
      const { data } = await triagePatientApi(null, 0).post(
        `/${dataRequest.initialAttentionId}`,
        dataRequest
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getClasificationTriages({ commit }): Promise<object> {
    try {
      const { data } = await triageGeneralApi().get(``);
      const { success, message } = data;

      commit("setClassificationTriages", data.data);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getTriageById({ commit }, { idTriage }) {
    try {
      const { data } = await triagePatientApi(null, 0).get(`/${idTriage}`);
      const { success, message } = data;

      commit("setTriageById", data.data);

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async getPatientDetailByTriageId({ commit }, { idTriage }) {
    try {
      const { data } = await triagePatientApi(null, 0).get(
        `/${idTriage}/patient`
      );
      const { success, message } = data;

      commit("setPatientByTriageId", data.data);
      
      commit("patients/setPatientDetailsAdditionalInfo", data?.data?.attendant, {
        root: true,
      });

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async withdrawTriage({ commit }, { idInitialAttention, reason }) {
    try {
      const { data } = await initialAttentionsApi().post(
        `/${idInitialAttention}/triage/remove`,
        { reason: reason }
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
  async setInTriage({ commit }, { idInitialAttention, reason }) {
    try {
      const { data } = await initialAttentionsApi().post(
        `/${idInitialAttention}/triage/set`
      );
      const { success, message } = data;

      return { success, message };
    } catch (error: IError | any) {
      return errorException(error);
    }
  },
};
function errorException(error: IError) {
  let success = false;
  if (error.message == "Network Error") {
    const { message } = error;
    return { success, message };
  }
  const data = error.response?.data;
  if (data) {
    const { success, message } = data;
    return { success, message };
  }
  const message = "Error";
  return { success, message };
}
export default actions;

import { isAuthenticatedGuard } from "@/router/auth-guard";

export default [
  {
    path: "/gestion-de-talento-humano/planificacion-de-programas",
    name: "HRPlanningProgramsListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Planning Programs List View" */ "@/views/human-resourses-view/planning-programs/planning-programs-list-view/HRPlanningProgramsListView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/planificacion-de-programas/crear-planificacion-de-programas",
    name: "HRPlanningProgramsCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Planning And Development Create View" */ "@/views/human-resourses-view/planning-programs/planning-programs-create-view/HRPlanningProgramsCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/planificacion-de-programas/editar-planificacion-de-programas",
    name: "HRPlanningProgramsEditView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Planning And Development Edit View" */ "@/views/human-resourses-view/planning-programs/planning-programs-edit-view/HRPlanningProgramsEditView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/planificacion-de-programas/ver-planificacion-de-programas",
    name: "HRPlanningProgramsShowView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Planning Programs Show View" */ "@/views/human-resourses-view/planning-programs/planning-programs-show-view/HRPlanningProgramsShowView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/noticias",
    name: "HRNewsListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR News List View" */ "@/views/human-resourses-view/info-news/news-list-view/HRNewsListView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/noticias/crear-noticias",
    name: "HRNewsCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR News Create View" */ "@/views/human-resourses-view/info-news/news-create-view/HRNewsCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/noticias/editar-noticias",
    name: "HRNewsEditView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR News Edit View" */ "@/views/human-resourses-view/info-news/news-edit-view/HRNewsEditView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/noticias/ver-noticia",
    name: "HRNewsShowView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR News Show View" */ "@/views/human-resourses-view/info-news/news-show-view/HRNewsShowView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/registro-de-participantes",
    name: "ParticipantRegistryListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Participant Registry List View" */ "@/views/human-resourses-view/participant-registry/participant-registry-list-view/ParticipantRegistryListView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/registro-de-participantes/crear-registro-de-participantes",
    name: "ParticipantRegistryCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Participant Registry Create View" */ "@/views/human-resourses-view/participant-registry/participant-registry-create-view/ParticipantRegistryCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/registro-de-participantes/editar-registro-de-participantes",
    name: "ParticipantRegistryEditView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Participant Registry Edit View" */ "@/views/human-resourses-view/participant-registry/participant-registry-edit-view/ParticipantRegistryEditView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/registro-de-participantes/ver-registro-de-participantes",
    name: "ParticipantRegistryShowView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "Participant Registry Show View" */ "@/views/human-resourses-view/participant-registry/participant-registry-show-view/ParticipantRegistryShowView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/reconocimiento-y-premios",
    name: "HRAwardsAndBadgesListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Awads And Badges List View" */ "@/views/human-resourses-view/awards-badges/awards-and-badges-list-view/HRAwardsAndBadgesListView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/reconocimiento-y-premios/crear-reconocimiento-y-premios",
    name: "HRAwardsAndBadgesCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Awards And Badges Create View" */ "@/views/human-resourses-view/awards-badges/awards-and-badges-create-view/HRAwardsAndBadgesCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/reconocimiento-y-premios/editar-reconocimiento-y-premios",
    name: "HRAwardsAndBadgesEditView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Awards And Badges Edit View" */ "@/views/human-resourses-view/awards-badges/awards-and-badges-edit-view/HRAwardsAndBadgesEditView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/reconocimiento-y-premios/ver-reconocimiento-y-premios",
    name: "HRAwardsAndBadgesShowView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Awards And Badges Show View" */ "@/views/human-resourses-view/awards-badges/awards-and-badges-show-view/HRAwardsAndBadgesShowView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/planificacion-de-desarrollo",
    name: "HRPlanningAndDevelopmentListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Planning And Developmen List View" */ "@/views/human-resourses-view/planning-development/planning-and-development-list-view/HRPlanningAndDevelopmentListView.vue"
      ),
  },
  {
    path: "/gestion-de-talento-humano/planificacion-de-desarrollo/crear-planificacion-y-desarrollo",
    name: "HRPlanningAndDevelopmentCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Planning And Development Create View" */ "@/views/human-resourses-view/planning-development/planning-and-development-create-view/HRPlanningAndDevelopmentCreateView.vue"
      ),
  },
  {
    path: "/gestion-de-talento-humano/planificacion-de-desarrollo/editar-planificacion-y-desarrollo",
    name: "HRPlanningAndDevelopmentEditView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Planning And Development Edit View" */ "@/views/human-resourses-view/planning-development/planning-and-development-edit-view/HRPlanningAndDevelopmentEditView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/planificacion-de-desarrollo/ver-planificacion-y-desarrollo",
    name: "HRPlanningConclusionsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Planning Conclusions View" */ "@/views/human-resourses-view/planning-development/planning-and-development-conclusions-view/HRPlanningConclusionsView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/objetivos",
    name: "HRTargetListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Target List View" */ "@/views/human-resourses-view/target-view/target-list-view/HRTargetListView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/crear-objetivos",
    name: "HRTargetCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Target Create View" */ "@/views/human-resourses-view/target-view/target-create-view/HRTargetCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/editar-objetivos",
    name: "HRTargetEditView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Target Edit View" */ "@/views/human-resourses-view/target-view/target-edit-view/HRTargetEditView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/ver-objetivos",
    name: "HRTargetConclusionsView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Target Conclusions View" */ "@/views/human-resourses-view/target-view/target-conclusions-view/HRTargetConclusionsView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/asistencias",
    name: "HRAttendanceListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Attendance List View" */ "@/views/human-resourses-view/attendance-view/attendance-list-view/HRAttendanceListView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/asistencias/crear-novedad",
    name: "HRAttendanceCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Attendance Create View" */ "@/views/human-resourses-view/attendance-view/attendance-create-view/HRAttendanceCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/asistencia/perfil-del-colaborador/:id",
    name: "HRAttendanceProfileView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Attendance List View" */ "@/views/human-resourses-view/attendance-view/attendance-profile-view/HRAttendanceProfileView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/licencias-y-vacaciones",
    name: "HRVacationLeaveListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Vacation Leave List View" */ "@/views/human-resourses-view/vacation-leave-view/vacation-leave-list-view/HRVacationLeaveListView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/licencias-y-vacaciones/crear-licencias-y-vacaciones",
    name: "HRVacationLeaveCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Vacation Leave Create View" */ "@/views/human-resourses-view/vacation-leave-view/vacation-leave-create-view/HRVacationLeaveCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/licencias-y-vacaciones/crear-licencias-y-vacaciones/:id",
    name: "HRVacationLeaveProfileView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Vacation Leave Profile View" */ "@/views/human-resourses-view/vacation-leave-view/vacation-leave-profile-view/HRVacationLeaveProfileView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/administracion-de-personal",
    name: "HRAdminUsersListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Admin User List View" */ "@/views/human-resourses-view/admin-user-view/admin-users-list-view/HRAdminUsersListView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/administracion-de-personal/crear-nuevo-colaborador",
    name: "HRAdminUsersCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Admin User Create View" */ "@/views/human-resourses-view/admin-user-view/admin-users-create-view/HRAdminUsersCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/administracion-de-personal/ver-colaborador/:id",
    name: "HRAdminUsersProfileView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Admin User Create View" */ "@/views/human-resourses-view/admin-user-view/admin-users-profile-view/HRAdminUsersProfileView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/nomina",
    name: "HRInvoiceListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Invoice  List View" */ "@/views/human-resourses-view/invoice-view/invoice-list-view/HRInvoiceListView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/crear-pago-de-nomina",
    name: "HRInvoiceCreateView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Invoice Create View" */ "@/views/human-resourses-view/invoice-view/invoice-create-view/HRInvoiceCreateView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/ver-perfil/:id",
    name: "HRInvoiceProfileView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Invoice Profile View" */ "@/views/human-resourses-view/invoice-view/invoice-profile-view/HRInvoiceProfileView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/encustas-y-retroalimentacion",
    name: "HRSurveyListView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR List Survey View" */ "@/views/human-resourses-view/surveys/survey-list-view/HRSurveyListView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/encustas-y-retroalimentacion/crear-encuesta",
    name: "HRCreateSurveyView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Create Survey View" */ "@/views/human-resourses-view/surveys/survey-create-view/HRCreateSurveyView.vue"
      ),
  },

  {
    path: "/gestion-de-talento-humano/encustas-y-retroalimentacion/editar-encuesta/:id",
    name: "HREditSurveyView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Edit Survey View" */ "@/views/human-resourses-view/surveys/survey-edit-view/HREditSurveyView.vue"
      ),
  },


  {
    path: "/gestion-de-talento-humano/encustas-y-retroalimentacion/ver-encuesta/:id",
    name: "HRShowSurveyView",
    beforeEnter: [isAuthenticatedGuard],
    component: () =>
      import(
        /* webpackChunkName: "HR Show Survey View" */ "@/views/human-resourses-view/surveys/survey-question-view/HRSurveyQuestionView.vue"
      ),
  },


];

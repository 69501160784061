import { ActionTree } from "vuex";
import { InitialAttentionsState } from "./state";
import store, { StateInterface } from "../index";
import { initialAttentionsApi } from "@/apis";
import errorException from "@/functions/ErrorException";

const actions: ActionTree<InitialAttentionsState, StateInterface> = {
  async loadInitialAttentions({ commit }, params) {
    commit("setResponse");

    try {
      const { data: response } = await initialAttentionsApi().get("", {
        params,
      });
      const { success, message, data } = response;

      commit("setInitialAttentions", data);
      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async loadInitialAttention({ commit }, id) {
    commit("setResponse");

    try {
      const { data: response } = await initialAttentionsApi().get(`/${id}`);
      const { success, message, data } = response;

      commit("setInitialAttention", data);
      commit("setResponse", { success, message });
      commit("patients/setPatientDetails", data?.patient, {
        root: true,
      });
      commit("patients/setPatientDetailsAdditionalInfo", data?.attendant, {
        root: true,
      });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
  async createInitialAttention({ commit }, bodyRequest) {
    commit("setResponse");
    try {
      const { data } = await initialAttentionsApi().post("", bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
      return { success, message };
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
      return errorException(error);
    }
  },
  async closeInitialAttention({ commit }, { id, reason }) {
    commit("setResponse");

    try {
      const { data } = await initialAttentionsApi().post(`/${id}/close`, {
        _method: "PUT",
        reason,
      });
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
  async getPatientByIdInitialAttention({ commit }, id) {
    try {
      const { data } = await initialAttentionsApi().get(`/${id}/patient`);
      const { success, message } = data;
      store.commit("patients/setPatient", data.data);

      return { success, message };
    } catch (error) {
      const { success, message } = errorException(error);
      commit("setResponse", { success, message });
    }
  },
};

export default actions;

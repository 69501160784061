import { schedulesApi } from "@/apis";
import errorException from "@/functions/ErrorException";
import { ActionTree } from "vuex";
import { StateInterface } from "../index";
import { AppointmentConfirmationState } from "./state";

const actions: ActionTree<AppointmentConfirmationState, StateInterface> = {
  async sendAppointmentConfirmation({ commit }, { type, bodyRequest }) {
    commit("setResponse");

    try {
      const { data } = await schedulesApi().post(`/${type}`, bodyRequest);
      const { success, message } = data;

      commit("setResponse", { success, message });
    } catch (error) {
      const response = errorException(error);
      commit("setResponse", response);
    }
  },
};

export default actions;

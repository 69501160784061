import { TemplateState } from "@/interfaces/global/interfaces";

function state(): TemplateState {
  return {
    template: [],
    data_form: null,
  };
}

export default state;


import Notify from 'quasar/src/plugins/Notify.js';;

export const useAlertV2 = () => {

  function showAlert(message: string, type = 'default', icon?: string, timeout?: number) {
    if(message === 'REQUEST_ALREADY_IN_PROGRESS') return
    switch (type) {
      case 'success':
        Notify.create({
          icon: !icon ? 'mdi-check-circle-outline' : icon,
          message: message,
          multiLine: true,
          timeout: !timeout ? 850 : timeout,
          classes: 'alert__message--success',
        })
        break

      case 'error':
        Notify.create({
          icon: !icon ? 'mdi-alert-circle' : icon,
          message: message,
          multiLine: true,
          timeout: !timeout ? 850 : timeout,
          classes: 'alert__message--error',
        })
        break

      case 'warning':
        Notify.create({
          icon: !icon ? 'mdi-alert-outline' : icon,
          message: message,
          multiLine: true,
          timeout: !timeout ? 850 : timeout,
          classes: 'alert__message--warning',
        })
        break

      case 'info':
        Notify.create({
          icon: !icon ? 'mdi-information' : icon,
          message: message,
          multiLine: true,
          timeout: !timeout ? 850 : timeout,
          classes: 'alert__message--info',
        })
        break

      default:
        Notify.create({
          icon: !icon ? 'mdi-timer' : icon,
          message: message,
          multiLine: true,
          timeout: !timeout ? 850 : timeout,
          color: 'primary',
        })
        break
    }
  }
  return {
    showAlert,
  }
}

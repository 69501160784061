import axios from "axios";
import apiBase from "@/apis/AxiosIntance";

export const authApi = axios.create({
    baseURL: `${process.env.VUE_APP_URL}/api/users`,
})

export const authApiV2 = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/users`;
  return apiBase;
};







import { IScheduleListCustom } from "@/interfaces/customs/ScheduleUI";

export interface SchedulesState {
  scheduleListBySpecialty: IScheduleListCustom[];
  scheduleListCard: [];
  scheduleCardLoading: boolean;
  scheduleListBySpecialtyLoading: boolean;
  schedulesCurrentPage: number;
  schedulesLastPage: number;
}
// create schedules state L UI

function state(): SchedulesState {
  return {
    scheduleListBySpecialty: [],
    scheduleListCard: [],
    scheduleCardLoading: false,
    scheduleListBySpecialtyLoading: false,
    schedulesCurrentPage: 0,
    schedulesLastPage: 0,
  };
}

export default state;

import axios from "axios";
import apiBase from "@/apis/AxiosIntance";

export const contractsV2Api = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/contracts/v2`;
  return apiBase;
};

export const contractsDashboardV2 = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/contracts/v2/dashboard`;
  return apiBase;
};

export const contractsStatusV2 = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/contracts/v2/changeStatus`;
  return apiBase;
};

export const dispatchAvailablesCareCenters = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/centers/getCareWOcontracts`;
  return apiBase;
};

export const dispatchAvailablesSpecialties = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/specialties`;
  return apiBase;
};

export const LoadAvailablesSpecialtiesV3Api = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/specialties/v3`;
  return apiBase;
};

export const dispatchClinicalServices = () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/contracts/v2/getServices`;
  return apiBase;
};

export const dispatchCareCenterStatus= () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/contracts/v2/changeCentersStatus/`;
  return apiBase;
};

export const dispatchSpecialtiesStatus= () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/contracts/v2/changeSpecialtiesStatus/`;
  return apiBase;
};


export const dispatchServiceStatus= () => {
  apiBase.defaults.baseURL = `${process.env.VUE_APP_URL}/api/settings/contracts/v2/changeServicesStatus`;
  return apiBase;
};

// --- old versions

export const contractsApi = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/contracts`,
});

export const contractsStatus = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/contracts/getContractsStatus`,
});

export const changeContractStatusApi = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/contracts/changeStatus`,
});

export const careCentersByContract = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/centers/getCareByContract`,
});

export const centersByContractList = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/centers/getCareByReference`,
});

export const allCenters = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/centers/getByTypeWP`,
});

export const specialitiesByContractList = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/specialties/getByContract`,
});

export const changeStatusSpeciality = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/contracts/changeSpecialtiesStatus`,
});

export const changeStatusCenter = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/contracts/changeCentersStatus`,
});

export const allSpecialities = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/specialties`,
});

export const createContract = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/contracts`,
});

export const updateContract = axios.create({
  baseURL: `${process.env.VUE_APP_URL}/api/settings/contracts`,
});

import { TemplateState } from "@/interfaces/global/interfaces";
import { Module } from "vuex";
import { StateInterface } from "../index";

import state from "./state";
import mutations from "./mutations";

const mentalExamModule: Module<TemplateState, StateInterface> = {
  namespaced: true,
  mutations,
  state,
};

export default mentalExamModule;

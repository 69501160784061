import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { MainMenuOptionsV2UI } from "@/interfaces/customs/IDashboard";

export const useAuth = () => {
  const store = useStore<StateInterface>();
  const menus = computed<MainMenuOptionsV2UI[]>(() =>
    !store.state.auth.data.menus ? [] : store.state.auth.data.menus
  );

  return {
    // State
    user: computed(() => store.state.auth.data.user),
    menus,
    token: computed(() => store.state.auth.data.authorization.token),
    firstLogin: computed(
      () => store.state.auth.data.first_login_or_not_change_password
    ),
    tokenChangePassword: computed(() => store.state.auth.tokenChangePassword),
    centers: computed(() => store.state.auth.data.centers),
    notifications: computed(() => store.state.auth.data.notifications),
    request: computed(() => store.state.auth.request),
    profileUrl: computed(() => store.state.auth.profileUrl),

    // Getters
    isLoginUser: computed<boolean>(() => store.getters["auth/isLoginUser"]),

    // Actions
    loginUser: <T>(user: T) => store.dispatch("auth/loginUser", user),
    logOutUser: <T>(token: T) => store.dispatch("auth/logOutUser", token),
    changePassword: <T>(pass: T) => store.dispatch("auth/changePassword", pass),
    forgotPassword: <T>(email: T) =>
      store.dispatch("auth/forgotPassword", email),
    _recoveryPassword: (email: string) =>
      store.dispatch("auth/recoveryPassword", email),
    _changePassword: (form: { new_password: string; c_new_password: string }) =>
      store.dispatch("auth/firstChangePassword", form),
    //Mutations
    isMaintainSesionUser: () => store.commit("auth/maintainSesionUser", ""),
    isSetUser: <T>(val: T) => store.commit("auth/setUser", val),
  };
};

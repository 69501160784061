import { MutationTree } from "vuex";
import { TemplateState, ITemplate } from "@/interfaces/global/interfaces";

const mutations: MutationTree<TemplateState> = {
  setTemplates(state: TemplateState, data_form: ITemplate | null): void {
    state.data_form = data_form;
  },
};

export default mutations;

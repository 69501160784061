import { IPatient } from "@/interfaces/global/interfaces";
export interface TriageState {
  triageList: object[];
  triageCurrentPage: number;
  triageLastPage: number;
  classifications: object[];
  states: object[];
  types: object[];
  destinations: string[];
  triageData: {
    triage: { id: number; attention_time: string; description: string };
    destination: string;
    readmission: boolean;
  } | null;
  triage: {
    patient: IPatient;
    reason: string;
  } | null;
  patientTriage: IPatient | null;
}
function state(): TriageState {
  return {
    triageList: [],
    triageCurrentPage: 1,
    triageLastPage: 1,
    classifications: [],
    states: [
      { label: "En espera de triage", value: "En espera de triage" },
      { label: "Triage reevaluado", value: "Triage reevaluado" },
      { label: "En triage", value: "En triage" },
      { label: "Triage", value: "Triage" },
      { label: "Todos", value: "" },
    ],
    types: [
      { label: "Todos", value: "" },
      { label: "Gestante", value: "Gestante" },
      { label: "Primera infancia", value: "Primera infancia" },
      { label: "Infancia", value: "Infancia" },
      { label: "Adolescencia", value: "Adolescencia" },
      { label: "Juventud", value: "Juventud" },
      { label: "Adultez", value: "Adultez" },
      { label: "Persona mayor", value: "Persona mayor" },
      { label: "Inscrito a programa", value: "Inscrito a programa" },
    ],
    destinations: [
      "Urgencias",
      "Hospitalización",
      "Consulta externa",
      "Morgue",
    ],
    triageData: null,
    triage: null,
    patientTriage: null,
  };
}

export default state;

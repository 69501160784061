import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import {
  IDasboardTrendsCustom,
  IDataInfoTrend,
} from "@/interfaces/customs/IDashboard";

export const useUsers = () => {
  interface Ifilters {
    centers_id: null | number | undefined;
    loading_centers_reference?: boolean;
    specialties_id?: null | number;
    loading_specialties_id?: boolean;
    full_reference: null | string;
    status: null | string;
    loading_status: boolean;
  }

  const store = useStore<StateInterface>();

  const { getters, dispatch } = useStore();

  const getTrendsInfo = computed<IDasboardTrendsCustom>(
    () => getters["users/getTrendsInfo"]
  );

  const getDataInfoTrend = computed<IDataInfoTrend>(
    () => getters["users/getDataInfoTrend"]
  );

  function loadHomeTrends() {
    dispatch("users/LoadHomeTrends");
  }

  function loadHomeInfo() {
    dispatch("users/LoadHomeInfo");
  }

  return {
    // State
    loginOTPOptions: computed<{ title: string; value: string }[]>(
      () => store.state.users.loginOTPOption
    ),
    loginOTPMethods: computed<
      {
        method: string;
        status: string;
        method_title: string;
        last_use: string;
        created_at: string;
      }[]
    >(() => store.state.users.loginOTPMethods),
    loginOTPQuestions: computed<string[] | []>(
      () => store.state.users.loginOTPQuestions
    ),
    adminCareCenters: computed(() => store.state.users.adminCenters),
    colorAvatar: computed(() => store.state.users.colorAvatar),
    imgUser: computed(() => store.state.users.imgUser),
    isDashboard: computed(() => store.state.users.isDashboard),
    dataTableTypeUserCurrentPage: computed<number>(
      () => store.state.users.dataTableTypeUser.current_page
    ),
    dataTableTypeUserLastPage: computed<number>(
      () => store.state.users.dataTableTypeUser.last_page
    ),

    // Getters
    getTrendsInfo,
    getDataInfoTrend,
    getUserAdminEdit: computed(() => store.state.users.adminCareEdit),

    // Actions
    loadHomeInfo,
    loadHomeTrends,

    isGetUsersByIdRol: (token: any, id: number, page: any, center_id: number) =>
      store.dispatch("users/getUsersByIdRol", { token, id, page, center_id }),
    isGetUsersByIdRolFilter: (
      token: any,
      id: number,
      page: any,
      filters: Ifilters
    ) =>
      store.dispatch("users/getUsersByIdRolFilter", {
        token,
        id,
        page,
        filters,
      }),
    isGetCentersForType: (info: any) =>
      store.dispatch("users/getCentersForType", info),
    isGetCentersFullForType: (info: any) =>
      store.dispatch("users/getFullCenters", info),
    isGetUserByDocument: (info: any) =>
      store.dispatch("users/getUserByDocument", info),
    isGetUserByDocumentV2: (info: any) =>
      store.dispatch("users/getUserByDocumentV2", info),
    isGetgetHomeInfo: (data: any) => store.dispatch("users/getHomeInfo", data),
    isEditAdmin: (user: any, token: any) =>
      store.dispatch("users/editAdmin", { user, token }),
    isChangeUserStatus: (id: any, token: any) =>
      store.dispatch("users/changeUserStatus", { id, token }),
    isGetAdminCareByIdCc: (info: any) =>
      store.dispatch("users/getAdminCareByIdCc", info),
    isRegisterUserByRol: (info: any) =>
      store.dispatch("users/registerUserByRol", info),
    isGetContractById: (info: any) =>
      store.dispatch("users/getContractById", info),
    /* Permissions */
    isGetPermissions: (info: any) =>
      store.dispatch("users/getPermissions", info),
    isGetSearchPermissions: (info: any) =>
      store.dispatch("users/getSearchPermissions", info),
    /* Permissions */
    _updateMyProfile: (payload: object) =>
      store.dispatch("users/updateProfileInformation", payload),
    _getLoginOTPOptions: () => store.dispatch("users/getLoginOTPOptions"),
    _getQrOTP: (payload: object) =>
      store.dispatch("users/createOTPMethod", payload),
    _createOTPMethod: (payload: object) =>
      store.dispatch("users/createOTPMethod", payload),
    _disableMethodAuthOTP: (payload: object) =>
      store.dispatch("users/disableMethodAuthOTP", payload),
    _removeMethodAuthOTP: (payload: object) =>
      store.dispatch("users/removeMethodAuthOTP", payload),
    _getLoginOTPMethods: () => store.dispatch("users/getLoginOTPMethods"),
    _getLoginOTPQuestions: () => store.dispatch("users/getLoginOTPQuestions"),
    //Mutations
    isSetEditUser: (user: any) => store.commit("users/setEditUser", user),
    isSetColorAvatarUser: (color: any) =>
      store.commit("users/setColorAvatarUser", color),
    isSetImgUser: (img: any) => store.commit("users/setImgUser", img),
    isSetIsDashboard: (val: any) => store.commit("users/setDashboard", val),
    isSetDataTableTypeUser: (data: any) =>
      store.commit("users/setDataTypeUser", data),
  };
};

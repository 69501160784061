// vuex
import { ActionTree } from "vuex";

// apis
import {
  contractsV2Api,
  contractsStatusV2,
  contractsDashboardV2,
  dispatchAvailablesCareCenters,
  LoadAvailablesSpecialtiesV3Api,
  dispatchClinicalServices,
  dispatchServiceStatus,
  dispatchSpecialtiesStatus,
  dispatchCareCenterStatus,
} from "@/apis/contractApi";

//composables
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { useAlertV2 } from "@/composables/useAlertV2";

// interfaces
import { ContractsUI } from "./state";
import { StateInterface } from "../index";
import { IErrors } from "@/interfaces/customs/IShowErrors";
import {
  ContractListUI,
  IContractAvailableCenter,
  IContractAvailableServices,
  IContractCreate,
  IContractInfoById,
  IContractsSpecialties,
} from "@/interfaces/global/ContractsUI";

const { showAlert } = useAlertV2();
const { showCatchError } = showErrorInTS();
const { openMainLoader } = useMainLoader();

const actions: ActionTree<ContractsUI, StateInterface> = {
  //VIEWINFO - DASHBOARD
  async DispatchContractInfo({ commit }, currentContractId: number) {
    openMainLoader(true);

    try {
      const contractInfoOptions = await contractsV2Api().get(
        `/${currentContractId}`
      );

      const contractInfoOptionsRef: IContractInfoById =
        contractInfoOptions.data?.data[0] || null;

      const cardInfo = {
        centerRef: contractInfoOptionsRef.reference_center.name,
        generateDate: contractInfoOptionsRef.created_at,
        initialDate: contractInfoOptionsRef.initial_date,
        finalDate: contractInfoOptionsRef.final_date,
        status: contractInfoOptionsRef.status,
        remainDays: contractInfoOptionsRef.contract_remain,
      };

      const currentCareCenters = contractInfoOptionsRef.details.map(
        (centerRef, index) => {
          if (
            contractInfoOptionsRef.details.length ===
            contractInfoOptionsRef.care_center.length
          ) {
            const careCentersSelecteds = {
              name: contractInfoOptionsRef.care_center[index].name,
              status: centerRef.status,
              id: centerRef.id,
            };
            return careCentersSelecteds;
          } else {
            showAlert(
              "!No hay concordancia en los Centros de atencion!",
              "error"
            );
          }
        }
      );

      const clinicalALLServices = await dispatchClinicalServices().get("/");
      const clinicalAllServicesRef = clinicalALLServices.data.data;

      const currentClinicaHealthService = clinicalAllServicesRef[
        "Servicio de telemedicina"
      ].map((service: { id: number; name: string; status: string }) => ({
        id: service.id,
        name: service.name,
        status: "inactive",
      }));
      const currentClinicalTelemedicineService = clinicalAllServicesRef[
        "Servicio de salud"
      ].map((service: { id: number; name: string; status: string }) => ({
        id: service.id,
        name: service.name,
        status: "inactive",
      }));

      const clinicalServicesUpdate = contractInfoOptionsRef.clinical_services
        .filter((service) => service.type === "Servicio de salud")
        .map((setService) => ({
          id: setService.pivot.id,
          name: setService.name,
          status: setService.pivot.status,
          serviceId: setService.pivot.clinical_services_id,
        }));

      const clinicalServices = [];
      for (const service of currentClinicalTelemedicineService) {
        const matchingService = clinicalServicesUpdate.find(
          (item) => item.serviceId === service.id
        );
        if (matchingService) {
          clinicalServices.push(matchingService);
        } else {
          clinicalServices.push(service);
        }
      }
      const telemedicineServicesUpdate =
        contractInfoOptionsRef.clinical_services
          .filter((service) => service.type === "Servicio de telemedicina")
          .map((setService) => ({
            id: setService.pivot.id,
            name: setService.name,
            status: setService.pivot.status,
            serviceId: setService.pivot.clinical_services_id,
          }));

      const telemedicineServices = [];

      for (const telemedicineService of currentClinicaHealthService) {
        const matchingService = telemedicineServicesUpdate.find(
          (item) => item.serviceId === telemedicineService.id
        );
        if (matchingService) {
          telemedicineServices.push(matchingService);
        } else {
          telemedicineServices.push(telemedicineService);
        }
      }

      const contractSpecialties = contractInfoOptionsRef.specialties.map(
        (specialtySelect) => ({
          id: specialtySelect.pivot.id,
          name: specialtySelect.description,
          status: specialtySelect.pivot.status,
        })
      );

      commit("setContractViewInfoById", {
        cardInfo,
        currentCareCenters,
        clinicalServices,
        telemedicineServices,
        contractSpecialties,
      });

      const newStatusMessage = contractInfoOptions.data.message;

      showAlert(newStatusMessage, "success");
      openMainLoader(false);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      openMainLoader(false);

      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // VIEWINFO - CARE CENTERS STATUS UPDATE
  async DispatchCareCenterStatus(
    { commit, dispatch },
    {
      currentStatusId,
      currentContractId,
    }: { currentStatusId: number; currentContractId: number }
  ) {
    // loader
    commit(
      "general/setPopupLoadingMessage",
      "Cambiando el estado del centro de atención...",
      {
        root: true,
      }
    );
    commit("general/setPopupLoading", true, { root: true });

    try {
      const careCentersStatusContract = await dispatchCareCenterStatus().post(
        `/${currentStatusId}`
      );

      const newStatusMessage = careCentersStatusContract.data.message;
      void dispatch("general/LoadCenterRefSelectorOptions", null, {
        root: true,
      });
      void dispatch("general/LoadCareCenterSelectorOptions", null, {
        root: true,
      });
      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // ALERT
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", newStatusMessage, {
        root: true,
      });
      commit("general/setAlertType", "success", { root: true });

      void dispatch("DispatchContractInfo", currentContractId);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //popup
      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });
      // alert
      commit("general/setAlertTitle", "Listados de Contratos", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertType", "error", { root: true });
    }
  },

  // VIEWINFO - SPECIALTIES STATUS UPDATE
  async DispatchSpecialtiesStatus(
    { commit, dispatch },
    {
      currentStatusId,
      currentContractId,
    }: { currentStatusId: number; currentContractId: number }
  ) {
    // loader

    commit(
      "general/setPopupLoadingMessage",
      "Cambiando el estado de la especialidad...",
      {
        root: true,
      }
    );
    commit("general/setPopupLoading", true, { root: true });
    try {
      const serviceStatusContract = await dispatchSpecialtiesStatus().post(
        `/${currentStatusId}`
      );

      const newStatusMessage = serviceStatusContract.data.message;
      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // ALERT
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", newStatusMessage, {
        root: true,
      });
      commit("general/setAlertType", "success", { root: true });

      void dispatch("DispatchContractInfo", currentContractId);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });
      // alert
      commit("general/setAlertTitle", "Listados de Contratos", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertType", "error", { root: true });
    }
  },

  // VIEWINFO - SERVICE STATUS UPDATE
  async DispatchServiceStatus(
    { commit, dispatch },
    {
      currentStatusId,
      currentContractId,
    }: { currentStatusId: number; currentContractId: number }
  ) {
    // loader
    commit(
      "general/setPopupLoadingMessage",
      "Cambiando el estado del servicio...",
      {
        root: true,
      }
    );
    commit("general/setPopupLoading", true, { root: true });

    try {
      const serviceStatusContract = await dispatchServiceStatus().post(
        `/${currentStatusId}`
      );

      const newStatusMessage = serviceStatusContract.data.message;
      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // ALERT
      showAlert(newStatusMessage, "success");
      commit("general/setAlertType", "success", { root: true });
      void dispatch("DispatchContractInfo", currentContractId);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //popup
      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // CONTRACT UPDATE
  async DispatchUpdateContractOptions({ commit, dispatch }, infoUpdate) {
    // loader
    commit(
      "general/setPopupLoadingMessage",
      `Actualizando opciones de contrato ${infoUpdate.associated_contract}`,
      {
        root: true,
      }
    );
    commit("general/setPopupLoading", true, { root: true });
    try {
      const createContract = await contractsV2Api().post("/", infoUpdate);
      const newStatusMessage = createContract.data.message;
      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // ALERT
      showAlert(newStatusMessage, "success");
      void dispatch("general/LoadCenterRefSelectorOptions", null, {
        root: true,
      });
      void dispatch("general/LoadCareCenterSelectorOptions", null, {
        root: true,
      });
      commit("general/setAlertType", "success", { root: true });
      return "success";
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //popup
      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },
  // CONTRACT CREATE
  async CreateContract({ commit }, info: IContractCreate) {
    // loader
    commit("general/setPopupLoadingMessage", "Creando contrato...", {
      root: true,
    });
    commit("general/setPopupLoading", true, { root: true });

    try {
      const createContract = await contractsV2Api().post("/", info);
      const newStatusMessage = createContract.data.message;

      //popup
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // ALERT
      showAlert(newStatusMessage, "success");
      return "success";
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //popup
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
      return "error";
    }
  },

  // PRELOAD INFO CONTRACT - EDIT
  async LoadInfoContractEdit({ commit }, contractId: number) {
    try {
      const contractInfoOptions = await contractsV2Api().get(`/${contractId}`);
      const contractInfoOptionsRef: IContractInfoById =
        contractInfoOptions.data?.data[0] || null;

      const cardInfo = {
        centerRef: contractInfoOptionsRef.reference_center.name,
        generateDate: contractInfoOptionsRef.created_at,
        initialDate: contractInfoOptionsRef.initial_date,
        finalDate: contractInfoOptionsRef.final_date,
        status: contractInfoOptionsRef.status,
        remainDays: contractInfoOptionsRef.contract_remain,
      };
      const currentCenterRef = {
        label: contractInfoOptionsRef.reference_center.name,
        id: contractInfoOptionsRef.reference_center.id,
      };

      const currentCareCenters = contractInfoOptionsRef.care_center.map(
        (centerRef) => ({ label: centerRef.name, value: centerRef.id })
      );
      const currentDateIni = contractInfoOptionsRef.initial_date;
      const currentDateEnd = contractInfoOptionsRef.final_date;
      const associatedContract = contractInfoOptionsRef.associated_contract;
      const clinicalServices = contractInfoOptionsRef.clinical_services
        .filter((service) => service.type === "Servicio de salud")
        .map((setService) => ({
          currentLabel: {
            label: setService.pivot.status === "active" ? "Si" : "No",
            value: setService.pivot.status,
            id: setService.id,
            serviceId: setService.pivot.id,
          },
          quanty: setService.pivot.clinical_services_count,
          serviceName: setService.name,
          options: [
            {
              label: "Si",
              value: "active",
              id: setService.id,
              serviceId: setService.pivot.id,
            },
            {
              label: "No",
              value: "inactive",
              id: setService.id,
              serviceId: setService.pivot.id,
            },
          ],
        }));
      const telemedicineServices = contractInfoOptionsRef.clinical_services
        .filter((service) => service.type === "Servicio de telemedicina")
        .map((setService) => ({
          currentLabel: {
            label: setService.pivot.status === "active" ? "Si" : "No",
            value: setService.pivot.status,
            id: setService.id,
            serviceId: setService.pivot.id,
          },
          quanty: setService.pivot.clinical_services_count,
          serviceName: setService.name,
          options: [
            {
              label: "Si",
              value: "active",
              id: setService.id,
              serviceId: setService.pivot.id,
            },
            {
              label: "No",
              value: "inactive",
              id: setService.id,
              serviceId: setService.pivot.id,
            },
          ],
        }));

      const contractSpecialties = contractInfoOptionsRef.specialties.map(
        (specialty) => ({
          contractId: specialty.pivot.contracts_id,
          specialtyId: specialty.pivot.specialties_id,
          status: specialty.pivot.status,
          id: specialty.pivot.id,
        })
      );

      const contractSpecialtiesSelection = contractInfoOptionsRef.specialties
        .filter((specialty) => specialty.pivot.status === "active")
        .map((specialtySelect) => ({
          label: specialtySelect.description,
          value: specialtySelect.pivot.specialties_id,
        }));

      commit("setContractInfoById", {
        cardInfo,
        currentCenterRef,
        currentCareCenters,
        currentDateIni,
        currentDateEnd,
        associatedContract,
        clinicalServices,
        telemedicineServices,
        contractSpecialties,
        contractSpecialtiesSelection,
      });

      const newStatusMessage = contractInfoOptions.data.message;

      // alert
      showAlert(newStatusMessage, "success");
      return "success";
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      // alert
      showAlert(String(showCatchError(errorParse)), "error");
      return "error";
    }
  },

  // EDITVIEW - SPECIALTIES
  async LoadAvailablesSpecialtiesV3({ commit }, centerRefId: number) {
    openMainLoader(true);
    const specialtiesURL = `?reference_center_id=${centerRefId}&paginate=0&status=active`;
    try {
      const availablesSpecialtiesList =
        await LoadAvailablesSpecialtiesV3Api().get(specialtiesURL);

      const availablesSpecialtiesOptionsRef =
        availablesSpecialtiesList.data.data;
      const availablesSpecialtiesOption = availablesSpecialtiesOptionsRef.map(
        (service: IContractsSpecialties) => ({
          label: service.description,
          value: service.id,
        })
      );

      commit("setAvailablesSpecialtiesOptions", availablesSpecialtiesOption);

      return "success";
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setAvailablesSpecialtiesOptions", []);

      // alert
      showAlert(String(showCatchError(errorParse)), "error");
      return "error";
    }
  },

  // EDITVIEW - TYPESERVICES
  async LoadClinicalServices({ commit }) {
    try {
      const clinicalServices = await dispatchClinicalServices().get("/");
      const clinicalServicesRef = clinicalServices.data.data;

      const telemedicineServices = clinicalServicesRef[
        "Servicio de telemedicina"
      ].map((telService: IContractAvailableServices) => ({
        currentLabel: {
          label: "No",
          value: "inactive",
          id: telService.id,
        },
        quanty: 0,
        serviceName: telService.name,
        options: [
          {
            label: "Si",
            value: "active",
            id: telService.id,
          },
          {
            label: "No",
            value: "inactive",
            id: telService.id,
          },
        ],
      }));

      const healthServices = clinicalServicesRef["Servicio de salud"].map(
        (clinicHealth: IContractAvailableServices) => ({
          currentLabel: {
            label: "No",
            value: "inactive",
            id: clinicHealth.id,
          },
          quanty: 0,
          serviceName: clinicHealth.name,
          options: [
            {
              label: "Si",
              value: "active",
              id: clinicHealth.id,
            },
            {
              label: "No",
              value: "inactive",
              id: clinicHealth.id,
            },
          ],
        })
      );

      commit("setTelemedicineServices", telemedicineServices);
      commit("setHealthServices", healthServices);

      return "success";
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setTelemedicineServices", []);
      commit("setHealthServices", []);
      // alert
      showAlert(String(showCatchError(errorParse)), "warning");
      return "error";
    }
  },

  // EDITVIEW - AVAILABLES CARE CENTERS
  async LoadAvailablesCareCenters({ commit }) {
    try {
      // LOAD - CENTERS LIST
      const availablesCareCentersList =
        await dispatchAvailablesCareCenters().get("?paginate=false");
      const availablesCentersOptionsrRef = availablesCareCentersList.data.data;
      const availablesCentersOption = availablesCentersOptionsrRef
        ? availablesCentersOptionsrRef.map(
            (center: IContractAvailableCenter) => ({
              label: center.name,
              value: center.id,
            })
          )
        : [];

      commit("setAvailablesCentersOptions", availablesCentersOption);

      return "success";
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit("setAvailablesCentersOptions", []);

      // alert
      showAlert(String(showCatchError(errorParse)), "warning");

      return "error";
    }
  },

  // LISTVIEW - DELETE
  async DeleteContract({ commit, dispatch }, contractId) {
    commit(
      "general/setPopupLoadingMessage",
      `Eliminando el contrato #${contractId}`,
      { root: true }
    );
    commit("general/setPopupLoading", true, { root: true });
    try {
      const contractDelete = await contractsV2Api().delete(String(contractId));
      const contractDeleteMessage = contractDelete.data.message;
      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      commit("general/setAlertMessage", contractDeleteMessage, {
        root: true,
      });
      void dispatch("general/LoadCenterRefSelectorOptions", null, {
        root: true,
      });
      void dispatch("general/LoadCareCenterSelectorOptions", null, {
        root: true,
      });
      commit("general/setAlertType", "success", { root: true });
      void dispatch("LoadContractsList", "?page=1");
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // LISTVIEW - CHANGE STATUS
  async ChangeStatusContract({ commit, dispatch }, contractId: number) {
    commit(
      "general/setPopupLoadingMessage",
      `Cambiando el estado del contrato #${contractId}`,
      { root: true }
    );
    commit("general/setPopupLoading", true, { root: true });
    try {
      const contractListChangeStatus = await contractsStatusV2().post(
        `/${contractId}`
      );
      const newStatusMessage = contractListChangeStatus.data.message;
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });

      // alert
      commit("general/setShowAlert", true, { root: true });
      commit("general/setAlertTitle", "", { root: true });
      void dispatch("general/LoadCenterRefSelectorOptions", null, {
        root: true,
      });
      void dispatch("general/LoadCareCenterSelectorOptions", null, {
        root: true,
      });
      commit("general/setAlertMessage", newStatusMessage, {
        root: true,
      });
      commit("general/setAlertType", "success", { root: true });

      void dispatch("LoadContractsList", contractId);
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      //popup
      // loader
      commit("general/setPopupLoadingMessage", "", { root: true });
      commit("general/setPopupLoading", false, { root: true });
      commit("general/setPopupOpen", false, { root: true });
      // alert
      showAlert(String(showCatchError(errorParse)), "error");
    }
  },

  // LISTVIEW - LIST
  async LoadContractsList({ commit }, { contractListURL }) {
    openMainLoader(true);
    commit("setContractListLoader", true);

    try {
      const contractList = await contractsV2Api().get(contractListURL);
      const contractListRef = contractList.data.data.data;
      const contractListRow = contractListRef.map(
        (contract: ContractListUI) => ({
          centerRef: contract.reference_center.name,
          centerRefId: contract.reference_center.name,
          contractName: contract.name,
          id: contract.id,
          name: contract.associated_contract,
          initialDate: contract.initial_date,
          finalDate: contract.final_date,
          remaining: contract.contract_remain,
          status: contract.status,
        })
      );

      const contractListMessage = contractList.data.message;
      const contractListCurrentPage = contractList.data.data.current_page;
      const contractListLastPage = contractList.data.data.last_page;

      const statusContracts = await contractsDashboardV2().get("/");
      const statusContractsRef = statusContracts.data.data;

      const active = statusContractsRef.active;
      const inactive = statusContractsRef.inactive;
      const expired = statusContractsRef.expired;

      //setup total
      commit("setTotalActives", active);
      commit("setTotalExpired", expired);
      commit("setTotalInactives", inactive);
      //popup
      commit("setAllContract", contractListRow);
      commit("setContractCurrentPage", contractListCurrentPage);
      commit("setContractLastPage", contractListLastPage);
      commit("setContractListLoader", false);
      openMainLoader(false);
      // alert
      commit("general/setAlertType", "success", { root: true });
      commit("general/setAlertTitle", "Listados de Contratos", { root: true });
      commit("general/setAlertMessage", contractListMessage, { root: true });
      commit("general/setShowAlert", true, { root: true });
    } catch (e) {
      const error = e as IErrors;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      // load
      commit("setAllContract", []);
      openMainLoader(false);
      commit("setContractListLoader", false);

      // alert
      commit("general/setAlertType", "error", { root: true });
      commit("general/setAlertTitle", "Listados de Contratos", { root: true });
      commit("general/setAlertMessage", showCatchError(errorParse), {
        root: true,
      });
      commit("general/setShowAlert", true, { root: true });
    }
  },
};

export default actions;

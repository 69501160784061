import { MutationTree } from "vuex";
import { ContractsUI } from "./state";

const mutation: MutationTree<ContractsUI> = {
  setContractsBreadcrumbs(state, urlPath) {
    state.contractsBreadcrumbs = urlPath;
  },

  setContractReset(state, reset) {
    state.contractReset = reset;
  },

  //form options

  setAvailablesCentersOptions(state, availableCenters) {
    state.availablesCentersOptions = availableCenters;
  },

  setAvailablesSpecialtiesOptions(state, specialtiesOptions) {
    state.availablesSpecialtiesOptions = specialtiesOptions;
  },

  setTelemedicineServices(state, serviceOptions) {
    state.telemedicineServices = serviceOptions;
  },
  setHealthServices(state, serviceOptions) {
    state.healthServices = serviceOptions;
  },

  /* popup */
  setPopupOpen(state, status) {
    state.popupOpen = status;
  },
  setPopupLoading(state, status) {
    state.popupLoading = status;
  },
  setPopupLoadingMessage(state, message) {
    state.popupLoadingMessage = message;
  },
  setPopupTitle(state, title) {
    state.popupTitle = title;
  },
  setPopupMessage(state, message) {
    state.popupMessage = message;
  },

  /* fields */
  setSpecialitySelectedId(state, speciality) {
    state.specialitySelectedId = speciality;
  },

  setCenterSelectedId(state, center) {
    state.centerSelectedId = center;
  },

  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser;
  },
  setAllContract(state, contractsList) {
    state.contractsList = contractsList;
  },
  setSelectedContract(state, contractSelected) {
    state.contractSelected = contractSelected;
  },
  // Total compilet status
  setTotalActives(state, total) {
    state.totalActives = total;
  },
  setTotalExpired(state, total) {
    state.totalExpired = total;
  },
  setTotalInactives(state, total) {
    state.totalInactives = total;
  },

  setContractCurrentPage(state, contractPage) {
    state.contractCurrentPage = contractPage;
  },

  setContractLastPage(state, lastPage) {
    state.contractLastPage = lastPage;
  },

  setContractsPagination(state, pagination) {
    state.contractsPagination = pagination;
  },

  setContractListLoader(state, status) {
    state.contractListLoader = status;
  },

  setloadingSpecialities(state, newStatus) {
    state.loadingSpecialities = newStatus;
  },

  setRowSpeciality(state, specialities) {
    state.rowSpeciality = specialities;
  },

  setLoadingCenterList(state, newStatus) {
    state.loadingCenterList = newStatus;
  },
  setRowCenters(state, centers) {
    state.rowCenters = centers;
  },

  setContractStatus(state, { id, status }) {
    state.contractsList = state.contractsList.map((itemUpdate) => {
      if (itemUpdate.id === id) {
        return {
          ...itemUpdate,
          status,
        };
      }
      return itemUpdate;
    });
  },

  setContractInfoById(state, contractInfo) {
    state.contractInfoById = contractInfo;
  },

  setContractViewInfoById(state, contractInfo) {
    state.contractViewInfoById = contractInfo;
  },

  //update contract view
  setContractSpecialities(state, specialities) {
    state.contractSpecialities = specialities;
  },
};

export default mutation;

import { useStore } from "vuex";
import { StateInterface } from "@/store/index";
import { computed } from "vue";
import { ITemplate } from "@/interfaces/global/interfaces";

export const useMentalExams = () => {
  const store = useStore<StateInterface>();
  return {
    // States
    templates: computed(() => store.state.mentalExam.template),
    mentalExams: computed(() => store.state.mentalExam.data_form),

    // Mutation
    _setMentalExams: (templates: ITemplate | null) =>
      store.commit("mentalExam/setTemplates", templates),
  };
};

// vuex
import { ActionTree } from "vuex";

// api
import { supportApi } from "@/apis/supportApi";

// composables
import { useMainLoader } from "@/components/loader/hooks/useMainLoader";
import { showErrorInTS } from "@/composables/showErrorInTS/showErrorInTS";
import { useRedirectURL } from "@/composables/useRedirectURL";
import { useAlertV2 } from "@/composables/useAlertV2";

// interfaces
import { ISupportCustom, ISupportResponse } from "@/interfaces/customs/ISupportCustom";
import { ErrorsUI } from "@/interfaces/customs/ErrorException";
import { StateInterface } from "../index";
import { ISupportChat, ISupportDateRange, ISupportList, ISupportTicketInfo } from "@/interfaces/global/ISupport";

const { openMainLoader } = useMainLoader();
const { showCatchError } = showErrorInTS();
const { goToURLMutation } = useRedirectURL()
const { showAlert } = useAlertV2()

const actions: ActionTree<ISupportCustom, StateInterface> = {

  async LoadTicketsByDate({ commit },{centerRefId,careCenterId}) {
    commit('setLoaderSupportList', true)
    commit('setSupportDateRange', { dateRangeIni: '', dateRangeEnd: '' })
     const dateRangeUrl = `/dates?reference_center_id=${centerRefId}&care_center_id=${careCenterId ?? ""}`
  
     try {
      const supportDateRange = await supportApi().get(dateRangeUrl)
      const dateRange: ISupportDateRange = supportDateRange.data.data
      commit('setSupportDateRange', { dateRangeIni: dateRange.start, dateRangeEnd: dateRange.end })
      commit('setLoaderSupportList', false)
     } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit('setSupportDateRange', { dateRangeIni: '', dateRangeEnd: '' })
      showAlert(String(showCatchError(errorParse)), 'error')
      commit('setLoaderSupportList', false)
     }
  },
  
  async LoadTickedList(
    { commit },
    {
      page,
      rows,
      centerRefId,
      careCenterId,
      status,
      type,
      priority,
      dateIni,
      endIni,
      userTypeId,
    }
  ) {

    const validateEmpty = (valueType: string | number) => valueType === 0 || valueType === 'Todos' ? '' : valueType;

    openMainLoader(true)
    commit('setTicketListStatus', true)
 
    try {
      const ticketsURL = `?page=${page}&rows=${rows}&reference_center_id=${centerRefId}&care_center_id=${careCenterId}&status=${validateEmpty(status)}&type=${validateEmpty(type)}&priority=${validateEmpty(priority)}&from_date=${dateIni === '' ? '' : dateIni}&to_date=${endIni === '' ? '' : endIni}&user_type_id=${validateEmpty(userTypeId)}`
      const supportTicketList = await supportApi().get(ticketsURL)
      const supportTicketOptions = supportTicketList.data.data.data.map((ticket: ISupportList) => ({
        id: ticket.id,
        careCenterName: ticket.care_center.name,
        careCenterId: ticket.care_center.id,
        type: ticket.type,
        subject: ticket.subject,
        userTypeId: ticket.created_by.get_user_permissions.id,
        userTypeName: ticket.created_by.get_user_permissions.role,
        userName: ticket.created_by.name,
        lastUpdate: ticket.last_updated_at_formatted,
        priority: ticket.priority,
        status: ticket.status,
      }))

      const supportListMessage = supportTicketList.data.message
      const currentPage = supportTicketList.data.data.current_page
      const lastPage = supportTicketList.data.data.last_page

      commit('setTicketList', supportTicketOptions)
      commit('setFirstTicketPages', currentPage)
      commit('setLastTicketPages', lastPage)
      commit('setTicketListStatus', false)

      commit('setTicketsStatus', 'support/in-session')

      showAlert(supportListMessage, 'success')
      openMainLoader(false)
    

    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      commit('setTicketList', [])
      commit('setTicketListStatus', false)
      
      showAlert(String(showCatchError(errorParse)), 'error')
      openMainLoader(false);

    }
  },


  async CreatTicketSupport(
    { commit, dispatch },
    {
      centerRefId,
      careCenterId,
      type,
      priority,
      subject,
      message,
      documents
    }
  ) {

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    openMainLoader(true)

    const infoTicket = {
      reference_center_id: centerRefId,
      care_center_id: careCenterId,
      type,
      priority,
      subject,
      message,
      documents
    }

    try {
      const supportTicket = await supportApi().post('/', infoTicket, { headers })

      const supportTicketRef = supportTicket.data

      commit("setTicketListStatus", false);
      dispatch('notifications/loadNotifications', true, {
        root: true
      })


      showAlert(supportTicketRef.message, 'success')
      goToURLMutation('SupportView')

    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      openMainLoader(false);

      showAlert(String(showCatchError(errorParse)), 'error')
      goToURLMutation('SupportCreateView')
    }
  },

  async CreateSupportByTicket(
    { commit },
    {
      ticketId,
      closeTicket,
      message,
      documents
    }
  ) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    openMainLoader(true)

    const ticketResponseInfo = {
      close_ticket: closeTicket,
      message: message,
      documents
    }

    try {
      const ticketResponse = await supportApi().post(`/${ticketId}`, ticketResponseInfo, { headers })

      const ticketResponseRef = ticketResponse.data

      commit("setTicketListStatus", false);

      showAlert(ticketResponseRef.message, 'success')
      goToURLMutation('SupportView')

    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      openMainLoader(false);

      showAlert(String(showCatchError(errorParse)), 'error')
      goToURLMutation('SupportCreateView')
    }
  },


  async LoadSupportByTicket({ commit }, ticketId) {
    openMainLoader(true)

    try {
      const loadTicket = await supportApi().get(`/${ticketId}`)
      const loadChatMessages = await supportApi().get(`/${ticketId}/messages`)
      const loadTicketRef = loadTicket.data
      const loadChatMessagesRef: ISupportChat[] = loadChatMessages.data.data
      const loadTicketInfo: ISupportTicketInfo = loadTicket.data.data

      const ticketInfo = {
        ticketId: loadTicketInfo.id,
        centerRefId: loadTicketInfo.reference_center.name,
        centerRefName: loadTicketInfo.reference_center.id,
        careCenterId: loadTicketInfo.care_center.id,
        careCenterName: loadTicketInfo.care_center.name,
        createDate: loadTicketInfo.created_at_formatted,
        updateDate: loadTicketInfo.last_updated_at_formatted,
        type: loadTicketInfo.type,
        subject: loadTicketInfo.subject,
        comment: loadTicketInfo.message,
        documents: loadTicketInfo.documents,
        priority: loadTicketInfo.priority,
        status: loadTicketInfo.status
      }



      const chatMessages = loadChatMessagesRef.map(chat => ({
        userName: chat.created_by.name,
        userId: chat.created_by.user_info_id,
        role: chat.created_by.get_user_permissions.role,
        roleId: chat.created_by.get_user_permissions.id,
        message: chat.message,
        messageId: chat.id,
        documents: chat.documents,
        time: chat.time_ago,
        type: chat.type
      }))

      commit('setTicketInfo', ticketInfo)
      commit('setChatMessages', chatMessages)
      commit('setTicketViewStatus', 'support/in-sesion')


      openMainLoader(false)
      showAlert(loadTicketRef.message, 'success')

    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      showAlert(String(showCatchError(errorParse)), 'error')
      openMainLoader(false);
      goToURLMutation('SupportCreateView')
    }
  },



  async UpdatePriority({ commit }, { ticketId, priority }: { ticketId: number, priority: string }) {
    openMainLoader(true)

    try {

      const updatePriority = await supportApi().post(`/${ticketId}/priority`, {
        _method: "PATCH",
        priority: priority
      })

      const updatePriorityRef = updatePriority.data

      commit('setTicketViewStatus', 'support/reload')
      showAlert(updatePriorityRef.message, 'success')
      openMainLoader(false)

    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      showAlert(String(showCatchError(errorParse)), 'error')
      openMainLoader(false);
      goToURLMutation('SupportCreateView')
    }
  },

  async UpdateTicketStatus({ commit }, ticketStatus) {
    openMainLoader(true)

    try {

      const updateStatus = await supportApi().post(`/${ticketStatus}/close`, {
        _method: "PATCH",
      })

      const updateStatusRef = updateStatus.data
      const loadTicketInfo = updateStatus.data.data

      const ticketInfo = {
        ticketId: loadTicketInfo.id,
        centerRefId: loadTicketInfo.reference_center.name,
        centerRefName: loadTicketInfo.reference_center.id,
        careCenterId: loadTicketInfo.care_center.id,
        careCenterName: loadTicketInfo.care_center.name,
        createDate: loadTicketInfo.created_at_formatted,
        updateDate: loadTicketInfo.last_updated_at_formatted,
        type: loadTicketInfo.type,
        subject: loadTicketInfo.subject,
        comment: loadTicketInfo.message,
        documents: loadTicketInfo.documents,
        priority: loadTicketInfo.priority,
        status: loadTicketInfo.status
      }

      commit('setTicketInfo', ticketInfo)


      commit('setTicketViewStatus', 'support/reload')


      showAlert(updateStatusRef.message, 'success')
      openMainLoader(false)

    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));

      showAlert(String(showCatchError(errorParse)), 'error')
      openMainLoader(false);
      goToURLMutation('SupportCreateView')
    }
  },


  async UpdateSupportMessage({ commit, getters, dispatch }, { ticketId, bodyRequest }: { ticketId: number, bodyRequest: ISupportResponse }) {

    openMainLoader(true)

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const bodyInfo = {
      close_ticket: bodyRequest.closeTicket,
      message: bodyRequest.message,
      documents: bodyRequest.documents
    }

    const infoTicket = getters.getTicketInfo

    try {

      const updateSupportMessage = await supportApi().post(`/${ticketId}/message`, { ...bodyInfo }, { headers })

      const UpdateSupportMessageRef = updateSupportMessage.data

      const chat = updateSupportMessage.data.data

      const loadChatMessage = {
        userName: chat.created_by.name,
        userId: chat.created_by.user_info_id,
        role: chat.created_by.get_user_permissions.role,
        roleId: chat.created_by.get_user_permissions.id,
        message: chat.message,
        messageId: chat.id,
        documents: chat.documents,
        time: chat.time_ago,
        type: chat.type
      }


      const ticketInfo = {
        ...infoTicket,
        status:  chat.support_ticket.status
      }

      commit('setTicketInfo', ticketInfo)
      commit('setUpdateTicketMessage', loadChatMessage)

      dispatch('notifications/loadNotifications', true, {
        root: true
      })

      showAlert(UpdateSupportMessageRef.message, 'success')
      setTimeout(() => {
        openMainLoader(false)
      }, 500)

    } catch (e) {
      const error = e as ErrorsUI;
      const message = error.message;
      const response = error.response;
      const errorParse = JSON.parse(JSON.stringify({ message, response }));
      showAlert(String(showCatchError(errorParse)), 'error')
      openMainLoader(false);
      goToURLMutation('SupportCreateView')
    }
  }
};

export default actions;

import store from "@/store";
import { useRecaptcha } from "@/composables/useRecaptcha";
import { useAlertV2 } from "@/composables/useAlertV2";
/* import { useRouter } from "vue-router"; */

/* const router = useRouter(); */
const { showAlert } = useAlertV2();
const { loadRecaptcha, recaptchaToken } = useRecaptcha();

export const isAuthenticatedGuard = async (to: any, from: any, next: any) => {
  if (
    window.sessionStorage.getItem("isAuthenticated") === "1" &&
    window.sessionStorage.getItem("user")
  ) {
    if (
      from.name == undefined &&
      to.name == "dashboard" &&
      sessionStorage.getItem("lastPath") == null
    ) {
      next();
    }

    if (to.name !== "dashboard") {
      sessionStorage.setItem("lastPath", to.name);
      if (to?.query) {
        sessionStorage.setItem("query", JSON.stringify(to.query));
      }

      if (to?.params?.id) {
        sessionStorage.setItem("id", to.params.id);
      }
    }
    if (from.name == undefined && to.name == "dashboard") {
      if (sessionStorage.getItem("id")) {
        next({
          name: sessionStorage.getItem("lastPath"),
          params: { id: sessionStorage.getItem("id") },
          query: JSON.parse(sessionStorage.getItem("query") as string),
        });
      } else {
        if (sessionStorage.getItem("lastPath") === "dashboard") {
          next();
        } else {
          next({
            name: sessionStorage.getItem("lastPath"),
            query: JSON.parse(sessionStorage.getItem("query") as string),
          });
        }
      }
    } else {
      sessionStorage.setItem("lastPath", to.name);
      next();
    }
  } else {
    sessionStorage.clear();
    loadRecaptcha();
    next("/auth");
  }
};

export const isFirtsLoginGuardTokenParam = async (
  to: any,
  from: any,
  next: any
) => {
  if (
    from.path == "/auth" &&
    sessionStorage.getItem("first_login") === "true"
  ) {
    const urlParams = new URLSearchParams(window.location.search);
    const token: any = urlParams.get("token");
    store.state.auth.tokenChangePassword = token;
    next();
  } else {
    next("/auth");
  }
};

export const changePasswordGuardTokenParam = async (
  to: any,
  from: any,
  next: any
) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token: any = urlParams.get("token");
  store.state.auth.tokenChangePassword = token;
  next();
};

export default {
  isAuthenticatedGuard,
  isFirtsLoginGuardTokenParam,
  changePasswordGuardTokenParam,
};
